import request from '@/utils/request';

// 登录
export const login = (query) => {
    return request({
        url: `/hbapi-app/apUser/login`,
        method: 'post',
        data: query
    });
};

// 给用户发送OTP验证码
export const sentOtpVerificationCode = (query) => {
    return request({
        url: `/hbapi-app/apUser/sentOtpVerificationCode`,
        method: 'post',
        data: query
    });
};

// 用户注册
export const register = (query) => {
    return request({
        url: `/hbapi-app/apUser/register`,
        method: 'post',
        data: query
    });
};

// 登出
export const logout = () => {
    return request({
        url: `/hbapi-app/apUser/logout`,
        method: 'post',
    });
};

// 获取用户信息
export const getUserInfo = () => {
    return request({
        url: `/hbapi-app/apUser/getUserInfoByToken`,
        method: 'post',
    });
};


// 获取员工关联的门店
export const getStaffStoreXrefByUsername = (query) => {
    return request({
        url: `/hbapi-core/rpc/getStaffStoreXrefByUsername`,
        method: 'post',
        data: query,
    });
};

// 获取协议
export const getAgreementInfo = () => {
    return request({
        url: `/hbapi-app/rpc/getAgreementInfo`,
        method: 'post',
    });
};

// 发送邮箱验证码
export const sentEmailByRegister = (query) => {
    return request({
        url: `/hbapi-app/rpc/sentEmailByRegister`,
        method: 'post',
        data: query,
    });
};

// 获取个人中心的统计数量
export const getUserDataByToken = () => {
    return request({
        url: `/hbapi-app/apUser/getUserDataByToken`,
        method: 'post'
    });
};

// 入口
export const getServiceCategoryList = () => {
    return request({
        url: `/hbapi-app/apBookingType/getServiceCategoryList`,
        method: 'post'
    });
};


// 版本號
export const getSystemVersion = () => {
    return request({
        url: `/hbapi-app/rpc/getSystemVersion`,
        method: 'post'
    });
};