import router from './router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getStore, setStore } from './utils/util'

NProgress.configure({ showSpinner: false })

const whiteList = ['/index', '/login', '/register', '/agreement']

router.beforeEach((to, from, next) => {
  NProgress.start()
  let token = getStore("token");
  if (token) {
    if (to.path === '/login' || to.path === '/index') {
      next({ path: '/home' })
    } else {
      next()
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()// 在免登录白名单，直接进入
    } else if (to.fullPath.indexOf('service=') !== -1) {
      const regex = /service=([^&]+)/;
      const match = to.fullPath.match(regex);

      if (match && match[1]) {
        setStore("urlServiceCategoryLabel", match[1]);
        next('/login') //携帶類型參數的定位到登錄頁
      }
    } else {
      next(`/index`) // 否则全部重定向到功能列表页
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
